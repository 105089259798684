import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="App Development Atlanta - Sionic Mobile Case Study - Websuasion"
        ogDescription="Are you looking for a professional app developer serving Atlanta, GA? See how we enabled Sionic to achieve their business goals."
        image="/images/case-studies/midtown-atlanta-application-development.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>App Development Atlanta</h1>
						<h3>A Case Study of Sionic Mobile</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled Sionic Mobile to achieve their app development goals by providing web portal development, website design, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web developer serving the midtown Atlanta GA area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/midtown-atlanta-application-development.jpg" className="rounded-image" alt="App Development Atlanta - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/sionic-mobile-logo.webp" alt="App Development Atlanta - Websuasion" />
							</p>
			        <p>
								Midtown Atlanta-based Sionic Mobile provides low-cost payments bundled with mobile marketing and customer loyalty to businesses and instant rewards to consumers at checkout. The Company’s universal rewards, called IONs, help merchants keep good customers coming back more often and are awarded in addition to customers’ credit card points. Consumers may spend IONs (1,000 IONs = $1.00) at any merchant in the ION Marketplace on anything at any time. Businesses use ION Loyalty® securely online, on mobile devices, or through their POS system, eliminating the need for new equipment. Consumers use the free ION Rewards® app for iOS and Android for in-store checkouts. For their cloud app development, Atlanta-based software developers were needed for Sionic Mobile to remain agile.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Ryan and the Websuasion team are a pleasure to work with - producing deliverables on time and within the original proposed budget. We're continuing our relationship with them as we continue to grow and transition our brand and digital assets.</Blockquote>
							<p>
							- Ron Herman, Founder & CEO
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/sionic-mobile-ron-herman.jpg" className="rounded-square" alt="App Development Atlanta - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Websites serving marketing content for differing audiences</li>
			        <li>Mobile optimized websites</li>
							<li>Solution enabling businesses to manage rewards accounts</li>
							<li>Portfolio access for investors</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Four unique desktop, tablet, and mobile responsive websites</li>
			        <li>Marketing content and styling to fit consumer, merchant and enterprise audiences</li>
							<li>Web application portal with robust functionality for businesses to manage accounts</li>
							<li>Investor web portal with separate administration and investor functionality</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Sionic Mobile Website Design</h2>
							<p>
								For the Sionic Mobile corporate site, we utilized a clean design with a combination of black and white photography in the site headings and contrasting color imagery with parallax and animated elements in the site body. We crafted the initial marketing content to outline their robust payment technology as succinctly and clearly as possible. This site was primarily aimed at potential partners and investors. As with all Websuasion designs, we provided custom responsive styling for desktop, mobile, and tablet.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/sionicmobile.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Sionic Mobile Website Design</h2>
							<p>
								The ION Rewards&reg; website was aimed at end-users of the payment and rewards platform. We took a fun approach to the ION Rewards site, adding lifestyle videos along with animation to illustrate how ION Rewards becomes a part of your daily life. The biggest challenge was explaining the benefits of using the system without getting unnecessarily  technical. We focused the marketing content on the ease of use and reward benefits their customers received. At each point in the website presentation, users are prompted to download the iOS and Android app, which then leads the user through a simple setup process.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/ionrewards.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Mobile Rewards Marketplace Web Design</h2>
							<p>
								In 2014, we created the ION Loyalty&reg; website for Sionic Mobile, which was aimed at merchants. In 2017, the ION Loyalty program was rebranded as the Mobile Rewards Marketplace&trade; and our team was asked to create a new site design as part of the change. The redesign was prompted by a shift toward providing white-label payment and rewards services and custom mobile apps for their partners. The challenge was how to best present content so that it worked not only for Sionic Mobile's own direct merchant relationships, but also for merchants who came in via a partner. The revision altered the content and site assets to showcase the features in common to each scenario.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/mobilerewardsmarketplace.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Shop2Give Web Design</h2>
							<p>
								In 2014, Sionic Mobile had an additional mobile app that was designed specifically for generating donations to your favorite causes each time a purchase was made using the Shop2Give app.
							</p>
							<p>
								<img src="/images/case-studies/s2gnow-website-design.jpg" alt="App Development Atlanta - Websuasion" />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>MRM Center Portal</h2>
							<p>
								In 2015, our initial role of web design was expanded to include programming of the <a href="https://mrmcenter.com">MRM Center</a> portal.
							</p>
							<p>
								For their <a href="https://websuasion.com/software-development-company/">portal app development</a>, Atlanta-based Sionic Mobile needed a solution that would allow businesses to manage their account, locations, users, advertisements, and gift cards. The portal we developed integrates with Sionic Mobile's API server and includes <a href="https://websuasion.com/front-end-web-developer/">detailed reporting functionality</a>, image upload and management, user management, multiple user roles, google map integration, and much more. The MRM portal manages seven distinct business types -- enterprise, merchant, wholesaler, reseller, agent, foundation, and fund -- each with their own <a href="https://websuasion.com/website-back-end-developer/">custom functionality</a>. Additionally, we created an administration level providing Sionic Mobile the ability to manage all accounts for customer service and support tasks.
							</p>
							<p>
								<img src="/images/case-studies/mrmcenter-web-development.jpg" alt="App Development Atlanta - Websuasion" />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Investor Portal</h2>
							<p>For their investor app development, Atlanta-based Sionic Mobile needed a custom portal solution. In mid-2015, we developed a secure investor portal which would allow Sionic Mobile's investors access to information regarding the value of their current holdings. We created a system with separate administration and investor functionality, allowing Sionic Mobile to easily keep their investors up to date.</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>On a continual basis, we serve as tech consultants for Sionic Mobile's ongoing projects, while providing server maintenance, and hosting of the existing sites. We work closely with their product team, the quality assurance team, and other third-party providers to ensure a productive and efficient development cycle.</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
